.designation {
  color: rgba(35, 35, 35, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &.designer {
    color: rgba(124, 185, 0);
    background: rgba(124, 185, 0, 0.15);
  }
  &.team-leader {
    color: #f26058;
    background: #f4cecc;
  }
  &.developer {
    color: #fe9e50;
    background: #ffece2;
  }
  &.tester {
    color: #3fbff5;
    background: #cce8f4;
  }
}

.user-module-wrapper{
  align-items: center;
}
@media(max-width:480.98px){
  .user-module-wrapper{
    flex-direction: column-reverse;
    align-items: flex-start;
    .content-header-right{
      width: 100%;
      .search-bar{
        input{
          width: 100%;
        }
      }
    }
  }
}

.user-tab-wrapper{
  .MuiTabs-flexContainer{
    .MuiTab-root{
      // font-weight: 500 !important;
    }
  }
}