.report-grid-list {
  .report-card {
    background-color: var(--body-bg);
    border: 2px solid var(--textgrey);
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    display: flex;
    gap: 12px;
    height: 100%;
    align-items: center;
    @media(max-width:576.98px){
      align-items: center;
    }
    &:hover {
      background-color: var(--light-grey);
    }      
    .setting-icon {
      img {
        min-width: 65px;
        max-width: 65px;
      }
    }

    .setting-name {
      margin: 0;
      font-size: 20px !important;
      color: var(--primary-text-color);
    }
  }

  @media (max-width: 942.98px) {
    flex-direction: column;
  }
}

.report-card-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}
.main-report-column {
  display: flex;
  gap: 20px;
  .report-column {
    background-color: var(--body-bg);
    width: calc(100vw - 186px);
    padding: 10px;
    border-radius: 10px;
  }
}

.card-container {
  margin-top: 7px;
  width: 250px !important;
}

.react-kanban-column::-webkit-scrollbar {
  width: 0;
}

/* Optional: If you want to hide the scrollbar in Firefox */
.react-kanban-column {
  scrollbar-width: none;
}

.content-wrapper .react-kanban-column:first-child {
  margin-left: -8px;
}

.react-kanban-column-header {
  text-align: center;
  background-color: var(--primary-text-color);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  margin-left: -7px !important;
  margin-right: -7px !important;
  color: var(--white);
  font-size: 15px !important;
  font-weight: 600 !important;
}

.custom-card-red {
  background-color: var(--delete-btn-hover) !important;
  border-left: 4px solid var(--delete-btn-icon) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.custom-card-green {
  background-color: var(--light-green) !important;
  border-left: 4px solid var(--green) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.custom-card-orange {
  background-color: var(--fire-btn-hover) !important;
  border-left: 4px solid var(--fire-btn-icon) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.react-kanban-card {
  margin-top: 10px !important;
  padding: 8px !important;
  cursor: pointer !important;
}

.field-title {
  font-size: 13px !important;
  color: var(--primary-text-color) !important;
  font-weight: 400 !important;
}

.datepickericon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 8px;
}

.content-layout {
  ::-webkit-scrollbar-thumb {
    background-color: var(--green) !important;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
}

.report-top-bar {
  margin-bottom: 24px;
  margin: 24px;
}

.time {
  border-bottom: 1px solid hsla(0, 0%, 47%, 0.2);
  border-radius: 0 !important;
  color: var(--color-grey);
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  padding: 10px 20px;
  text-align: center !important;
}

.report-top-filter {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.modal-date-picker .rs-picker-toggle.rs-btn .rs-picker-toggle-textbox {
  color: var(--primary-text-color);
}

.rbc-btn-group button {
  background-color: var(--primary-text-color) !important;
  color: var(--white) !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  padding: 8px 16px !important;
  width: 80px;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  margin: 0px 15px 0px 15px;
}
.rbc-time-header {
  border-bottom: 1px solid rgb(224, 224, 224);
  background-color: rgb(50, 50, 50);
  color: rgb(255, 255, 255) !important;
  z-index: 100000;
  font-weight: 600;
  font-size: 16px;
  position: sticky;
  top: 0;
  left: 0;
  .rbc-today {
    background-color: rgb(50, 50, 50);
  }
  button span {
    color: rgb(255, 255, 255) !important;
  }
}

.rbc-time-content > .rbc-time-gutter {
  text-align: center;
  border-right: 1px solid white;
  position: sticky;
  top: 48px;
  background-color: rgb(197, 227, 137);
  width: 80px;
  text-align: left !important;
  .rbc-label {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 0.875rem;
    align-items: center !important;
  }
  .rbc-time-slot {
    margin-top: 8px !important;
  }
}

.rbc-toolbar {
  margin-bottom: 15px !important;
}

.rbc-time-content {
  .rbc-today {
    background-color: var(--light-green) !important;
    .rbc-timeslot-group {
      border: 1px solid rgb(197, 227, 137);
    }
  }
}

.rbc-current-time-indicator {
  background-color: var(--green);
}

.rbc-toolbar .rbc-toolbar-label {
  font-weight: 600;
}

.rbc-time-view {
  max-height: calc(100vh - 230px);
  overflow: auto;
}

.activity-report-card {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  padding: 22px;
  margin-top: 25px;
  margin-bottom: 20px;
  margin-left: 20px;
  ::-webkit-scrollbar-thumb {
    background-color: var(--green) !important;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
}

.rs-calendar-table-cell-week-number {
  background-color: var(--light-green) !important;
}

.rbc-time-view .rbc-row {
  height: 40px !important;
}

.rbc-time-view .rbc-allday-cell {
  height: 40px !important;
}

.rbc-button-link {
  margin-top: 8px !important;
}

.custom-event {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.custom-event strong {
  font-weight: bold;
}

.custom-event p {
  margin: 0;
  font-size: 10px !important;
}

.rbc-event-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rbc-day-slot .rbc-event-label {
  display: none;
}

.rbc-timeslot-group {
  min-height: 70px !important;
  width: 200px !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
  border: none !important;
  width: 60px !important;
  height: 70px !important;
}
.custom-event.more-event {
  border: 2px solid #ccc;
}

.custom-event.more-event .rbc-event-content {
  font-style: italic;
  color: #888;
}

.report-text-black {
  color: var(--primary-text-color) !important;
}

.custom-icon-btn {
  width: 34.5px !important;
  height: 34.5px;
  min-width: unset !important;
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 13px;
    margin: 0;
    svg {
      fill: var(--white);
    }
  }
}

.h-400 {
  max-height: 400px !important;
  overflow: auto !important;
}
.blue-text {
  color: var(--rs-text-link) !important;
}

.calender-card {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  padding: 5px 22px 22px 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  ::-webkit-scrollbar-thumb {
    background-color: var(--green) !important;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
}

.activity-report-content-header {
  margin: 16px 0px 0px 20px;
  display: flex;
  flex-wrap: wrap;
  // gap: 30px;
  justify-content: space-between;
}

.muilt-select-field-report {
  width: 200px;
  .select__placeholder {
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .select__menu {
    z-index: 1002;
  }
}

.activity-padding {
  margin-top: 95px;
  @media (max-width: 1199.98px) {
    margin-top: 0px !important;
  }
}

.report-content-wrapper {
  margin: 24px 32px 32px 32px;
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .divider {
    border-color: var(--body-bg);
  }
  .report-card-wrapper {
    width: 100%;
    background-color: var(--white);
    border-radius: 12px;
    // padding: 24px;
    &.add-scroll-space {
      padding: 24px 14px 24px 24px;
      .over-flow-auto {
        overflow: auto;
        padding-right: 10px;
      }
    }
    &.add-bottom-scroll-space {
      padding: 24px 24px 14px 24px;
      .over-flow-bottom-auto {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;
      }
    }
  }
}

.lead-report {
  padding: 0px 22px 22px 22px;
  .MuiTabs-indicator {
    background-color: var(--green);
  }
}

.content-layout {
  .MuiTabs-indicator {
    background-color: var(--green);
  }
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
}
.report-tag-wrap {
  display: flex;
  gap: 8px;
  .MuiChip-root {
    text-transform: capitalize;
    color: var(--primary-text-color);
    border-radius: 4px;
    padding: 4px 0px;
    white-space: nowrap;
    font-weight: 500;
  }
}

.ongoing-class {
  background-color: var(--light-green) !important;
  &:hover {
    background-color: var(--light-green);
  }
}

.planned-class {
  background-color: var(--fire-btn-hover) !important;
  &:hover {
    background-color: var(--fire-btn-hover);
  }
}

.stopped-class {
  background-color: var(--delete-btn-hover) !important;
  &:hover {
    background-color: var(--delete-btn-hover);
  }
}

.allocation-card-open {
  background-color: var(--convert-btn-hover) !important;
  border-left: 4px solid var(--convert-btn-icon) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.allocation-card-requested {
  background-color: #f7f3a5 !important;
  border-left: 4px solid #ccaa02 !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.allocation-card-provided {
  background-color: var(--export-btn-hover) !important;
  border-left: 4px solid var(--export-btn-icon) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.allocation-card-standBy {
  background-color: #f9f9f9 !important;
  border-left: 4px solid var(--textgrey) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.allocation-card-closed {
  background-color: var(--fire-btn-hover) !important;
  border-left: 4px solid var(--fire-btn-icon) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.allocation-card-won {
  background-color: var(--light-green) !important;
  border-left: 4px solid var(--green) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.allocation-card-lost {
  background-color: var(--delete-btn-hover) !important;
  border-left: 4px solid var(--delete-btn-icon) !important;
  padding-left: 7px;
  height: 150px;
  cursor: pointer;
}

.report-load-more-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  position: sticky;
  left: 0px;
  .btn {
    width: 250px !important;
  }
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
  }
  .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #000;
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white; /* You can adjust the background color as needed */
  z-index: 100; /* Adjust the z-index to ensure the header is on top of other elements */
}

.head-row .MuiTableRow-root {
  display: flex !important;
  gap: 8px !important;
  .MuiTableCell-root {
    width: 270px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}

.body-row .MuiTableRow-root {
  display: flex !important;
  gap: 8px !important;
  .MuiTableCell-root {
    width: 270px !important;
    padding: 10px 10px 0px 10px !important;
  }
}

.report-page-wrapper {
  .left-wrapper {
    margin-right: 0;
    margin-bottom: 0px;
    @media (max-width: 1190.98px) {
      margin-right: 32px;
    }
  }
  .down-left-wrapper {
    margin-right: 0;
    margin-bottom: 20px;

    @media (max-width: 1190.98px) {
      margin-right: 32px;
    }
  }
  .right-wrapper {
    margin-left: 0;
    @media (max-width: 1190.98px) {
      margin-left: 32px;
    }
  }
}

.field-title-1 {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: (--primary-text-color) !important;
}

.tab-project-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  .content-header-right{
    @media(max-width:660.98px){
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 24px;
    }
  }
}
@media(max-width:1420.98px){
  .open{   
    .lead-allocation-wrapper{
      .content-header-right {
        padding: 16px 24px 0 24px;
      }
    }
  }
}
@media(max-width:991.98px){
  .lead-allocation-wrapper{
    .content-header-right {
      padding: 16px 24px 0 24px;
    }
  }
}
.leads-overview-filter{
  .report-top-filter{
    gap: 16px;
    flex-wrap: wrap-reverse;
  } 
}
@media(max-width:660.98px){
  .leads-overflow-wrapper{
    .content-header-right{
      padding: 0;
    }
  }
}
@media(max-width:576.98px){
  .leads-overview-filter{
    .report-top-filter{
      .modal-date-picker{
        flex: 1;
      }
    } 
  }
}
@media(max-width:470.98px){
  .leads-overflow-wrapper{
    .content-header-right{
      padding: 16px 24px 0 24px;
    }
  }
}
.activity-schedule-report{
  flex-wrap: wrap-reverse;
  .content-header-left{
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap-reverse;
  }
  .left-tool{
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
  
@media(max-width:1290.98px){
  .open{
    .activity-schedule-report{
      .content-header-right{
        width: 100%;
        .muilt-select-field-report{
          flex: 1;
        }
      }
    }
  }
}
@media(max-width:1110.98px){
  .open{
    .activity-schedule-report{
      .content-header-left{
        width: 100%;
        flex-direction: column-reverse;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        .title-with-btn{
          flex: 1;
          width: 100%;
        }
        .left-tool{
          width: 100%;
          .calendar-ui{
            width: 100%;
            .rs-picker{
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
@media(max-width:997.98px){
  .close{
    .activity-schedule-report{
      .content-header-right{
        width: 100%;
        .muilt-select-field-report{
          flex: 1;
        }
      }
      .content-header-left{
        width: 100%;
        flex-direction: column-reverse;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        .title-with-btn{
          flex: 1;
          width: 100%;
        }
        .left-tool{
          width: 100%;
          .calendar-ui{
            width: 100%;
            .rs-picker{
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
@media(max-width:576.98px){
  .close{
    .activity-schedule-report{
      .content-header-left{
        .left-tool{
          flex-wrap: wrap;       
          .custom-icon-btn{
            flex: 1;
          }
          .primary-btn{
            width: 100% !important;
          }
        }
      }
    }
  }
}
.activity-schedule-report{
  .muilt-select-field-report{
    .select__control{
      min-height: 42px;
    }
  }
}
// .billable-report-table-wrapper {
//   max-height: 100% !important;
//   table {
//     thead {
//       tr {
//         th {
//           z-index: 1 !important;
//           &:first-child {
//             left: unset !important;
//           }
//           &:last-child {
//             right: unset !important;
//           }
//         }
//       }
//     }

//     tbody {
//       tr {
//         td {
//           left: unset !important;
//           right: unset !important;
//           z-index: 1 !important;
//           &:last-child {
//             right: unset !important;
//             z-index: 1 !important;
//           }
//         }
//       }
//     }
//   }
// }
@media (max-width: 1024.98px) {
  .billable-report-table-wrapper {
    max-height: 100% !important;
    table {
      thead {
        tr {
          th {
            z-index: 1 !important;
            &:first-child {
              left: unset !important;
            }
            &:last-child {
              right: unset !important;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            left: unset !important;
            right: unset !important;
            z-index: 1 !important;
            &:last-child {
              right: unset !important;
              z-index: 1 !important;
            }
          }
        }
      }
    }
  }
}
@media(max-width:650.98px){
  .bid-overview-wrapper{
    .report-top-filter{
      flex-direction: column-reverse;
      width: 100%;
      h2{
        width: 100%;
      }
      .modal-date-picker{
        width: 100% !important;
      }
    }
  }
}