.add-multi-project {
  display: grid;
  grid-template-columns: 22fr 1fr;
  gap: 16px;
}
.full-width {
  grid-template-columns: 1fr !important;
}
.select-field {
  width: 306px !important;
}
.jodit-toolbar-button {
  background-color: white !important;
  color: black !important;

  &:hover {
    color: white !important;
    border: none !important;
  }
}
.jodit__preview-box {
  text-align: center;
}
.jodit-dialog__content {
  overflow-y: hidden !important;
}
.jodit-dialog__panel {
  width: 850px !important;
  height: 670px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

// .jodit-toolbar-button__button:hover {
//   color: white !important;
// }
.ql-editor p {
  color: #232323 !important;
}
.template-view p {
  color: #232323 !important;
}

.cv {
  .ql-container {
    height: 186px !important;
    overflow-y: auto;
  }
}

@media (max-width: 424px) {
  .cv-create-container {
    .footer-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 16px;
      padding-right: 16px;
    }
    .footer-wrapper .secondary-btn {
      margin-right: 0;
    }
  }
}

@media (max-width: 690px) {
  .cvbuilder-list-header {
    .search-bar input {
      width: 100%;
    }
  }
  .cv-listing-header {
    .content-header-right .search-bar input {
      width: 100%;
    }
  }
}
@media (max-width: 580px) {
  .cvbuilder-wrapper {
    .cv-listing-header {
      flex-direction: column;
      align-items: baseline;
      gap: 16px;
    }
  }
}
@media (( min-width: 992px)) {
  .cvbuilder-edit-container {
    .footer-wrapper {
      width: calc(100% - 300px);
      right: 0;
      left: auto;
      padding-left: 16px;
      padding-right: 16px;
    }
    .footer-wrapper .secondary-btn {
      margin-right: 0;
    }
  }
}
@media ((min-width: 992px) and ( max-width: 1223px)) {
  .cvbuilder-edit-container {
    .footer-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
@media (max-width: 930px) {
  .cvbuilder-edit-container {
    .footer-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding-left: 16px;
      padding-right: 16px;
    }
    .footer-wrapper .secondary-btn {
      margin-right: 0;
    }
  }
}
@media (max-width: 991px) {
  .cvbuilder-edit-container {
    .footer-wrapper {
      width: 100%;
    }
  }
}
@media (max-width: 762px) {
  .cvbuilder-edit-container {
    .footer-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
@media (max-width: 576px) {
  .cvbuilder-edit-container {
    .footer-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 0.2fr;
    }
  }
}
@media (max-width: 500px) {
  .cv-listing-container {
    .pagination-wrapper {
      gap: 0;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
.add-multi-project {
  .MuiAccordionSummary-root.Mui-expanded {
    background-color: #7cb900 !important;
    min-height: 56px !important;
    label {
      color: white !important;
    }
    svg {
      fill: white !important;
    }
  }
  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    // height: 56px !important;
    border-left: 3px solid var(--green);
  }
}

.cv-builder-wrapper {
  .card-wrapper {
    overflow-x: auto;
  }
}
.red-border {
  border: 2px solid red;
}
.not-found-msg {
  width: 100%;
  text-align: center;
  color: var(--textgrey);
  font-size: 16px;
}

.cv .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.38); /* Placeholder text color */
  font-style: italic; /* Placeholder text style */
  font-size: 15px; /* Placeholder text size */
  white-space: pre-wrap; /* Ensure placeholder text wraps */
  word-break: break-word;
  content: attr(data-placeholder); /* Use the placeholder attribute */
}
.pdf-page {
  margin: 10px auto;
  padding: 20px;
  width: 210mm; /* A4 width */
  height: 297mm; /* A4 height */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: white;
  page-break-after: always;
}
.full-width-btn {
  width: 100% !important;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.pdf-viewer {
  position: relative;
  height: 100%;
  overflow: auto;
}

.modal-wrapper-cv {
  padding: 18px;
  max-width: 910px;
  width: calc(100% - 24px);
  height: 95vh;
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    h3 {
      margin: 0;
    }
    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .error {
    color: var(--red) !important;
    font-size: 14px !important;
  }
  .footer {
    margin-top: 36px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      button {
        min-width: 120px;
      }
    }
  }
  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fail-icon {
    align-self: center;
    color: var(--red);
    font-size: 52px;
  }
  .success-icon {
    align-self: center;
    color: var(--green);
    font-size: 52px;
  }
  .warning-icon {
    align-self: center;
    color: rgb(238, 255, 0);
    font-size: 52px;
  }
  .feedback-text {
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }
  }
  .fail-text {
    background: rgba(195, 90, 103, 0.2) !important;
  }
  .success-text {
    background: rgba(90, 195, 92, 0.2) !important;
  }
  .warning-text {
    background: rgba(183, 195, 90, 0.2) !important;
  }
  .confirmation-text {
    background: rgba(140, 199, 20, 0.2) !important;
    border: 1px solid var(--color-green);
    border-radius: 10px;
    padding: 20px;
  }
}
