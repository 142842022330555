.side-drawer {
  .MuiDrawer-paper {
    width: 100%;
    .sidebar-tab-wrapper {
      .close-btn {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 14px;
        z-index: 99;
        svg {
          path {
            color: var(--primary-text-color);
          }
        }
      }
      .sidebar-tab {
        padding: 0 24px 0 24px;
        border-bottom: 1px solid var(--border);
        .tab-label-btn {
          color: var(--textgrey);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          &.Mui-selected {
            color: var(--primary-text-color);
          }
        }
        .contact-label {
          color: var(--primary-text-color);
          font-weight: 700;
          cursor: default;
        }
        .MuiTabs-indicator {
          height: 3px;
          background-color: var(--primary-text-color);
          border-radius: 5px;
        }
      }
      .filter-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        // margin-right: 20px !important;
        gap: 16px;
        margin-top: 24px;
        .btn {
          width: 120px;
        }
      }
      .filter-content-wrapper {
        .MuiBox-root {
          padding: 0 !important;
          .filer-accordion-wrapper {
            max-width: 500px;
            overflow: auto;
            max-height: calc(100vh - 65px);
            .MuiAccordion-root {
              // border: 0;
              padding-top: 12px;
              padding-bottom: 12px;
              border-bottom: 1px solid var(--border) !important;
              &.Mui-expanded {
                margin: 0;
              }
              &:before {
                display: none !important;
              }
              box-shadow: unset;
              border-radius: 0;
              .MuiAccordionSummary-expandIconWrapper {
                background-color: var(--edit-btn);
                width: 30px;
                height: 30px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  background-color: var(--edit-btn-hover);
                }
                svg {
                  width: 20px;
                  height: 20px;
                  path {
                    color: var(--primary-text-color);
                  }
                }
              }
              .accordion-title {
                padding: 12px 24px;
                min-height: auto !important;
                .action-btn {
                  min-width: 30px !important;
                  max-width: 30px;
                  height: 30px;
                  padding: 0 !important;
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
                .MuiAccordionSummary-content {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-right: 16px;
                  margin: 0;
                  .title-content {
                    color: var(--primary-text-color) !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                  }
                }
              }
              .filter-accordion-details {
                padding: 12px 24px 0 24px;
                .saved-filter-wrapper {
                  margin-bottom: 12px;
                  .saved-filter-content {
                    display: flex;
                    align-items: flex-start;
                    span {
                      font-feature-settings: "clig" off, "liga" off;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.4px;
                      &.title p{
                        color: var(--textgrey);
                        width: 185px;
                        white-space: nowrap;
                        // border: 2px solid red;
                        @media (max-width: 576.98px) {
                          width: 185px;
                        }
                      }
                      &.title-leads p{
                        color: var(--textgrey);
                        width: 160px;
                        white-space: nowrap;
                      }
                      &.desc {
                        color: var(--primary-text-color);
                        // width: 200px;
                        // @media (max-width: 576.98px) {
                        //   width: 240px;
                        // }
                        .dev-start-date{
                          // border: 2px solid red;
                          @media (max-width: 390.98px) {
                          display: flex;
                          flex-direction: column;
                        }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.filter-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
  .btn {
    width: 120px;
  }
}

.clients-module-wrapper {
  align-items: center;
  @media (max-width: 700.98px) {
    flex-direction: column-reverse;
    align-items: baseline;
  }
  .content-header-right {
    @media (max-width: 700.98px) {
      width: 100%;
      justify-content: flex-start !important;
    }
    .search-bar {
      @media (max-width: 700.98px) {
        width: 100%;
        max-width: calc(100% - 174px);
      }
      @media (max-width: 576.98px) {
        max-width: 100%;
      }
      input {
        @media (max-width: 700.98px) {
          width: 100%;
        }
      }
    }
    button {
      @media (max-width: 576.98px) {
        width: 100% !important;
      }
    }
  }
}
.client-contact-drawer {
  .sidebar-tab-wrapper {
    .sidebar-tab {
      padding-left: 10px !important;
      button {
        color: var(--primary-text-color) !important;
        opacity: 1 !important;
        font-weight: 500 !important;
      }
    }
  }
}

.view-clients-module-wrapper {
  align-items: center;
  flex-wrap: wrap-reverse;
  @media (max-width: 650.98px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .content-header-right {
    @media (max-width: 650.98px) {
      width: 100%;
      .add-btn {
        width: 100% !important;
        max-width: calc(100% - 60px) !important;
      }
    }
  }
}
@media (max-width: 390.98px) {
  .edit-client-wrapper {
    flex-direction: column-reverse;
  }
}
