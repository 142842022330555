.techstackpop{
    .css-jpln7h-MuiTabs-scroller{
        overflow: auto !important;;
       
     }
     .checkbox-list-wrapper {
        padding: 12px;
        display: flex;
        overflow: auto;
        padding-left: 0px;
      
        ul {
          padding: 0;
          padding-left: 0;
          min-height: calc(78vh - 150px);
          max-height: calc(78vh - 150px);
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          column-gap: 16px;
      
          li {
            width: 35vw;
            padding: 0;
            padding-bottom: 16px;
            padding-right: 30px;
            padding-left: 0px;
            border-right: 1px solid #f0f0f0;
            display: flex;
            gap: 8px;
            justify-content: space-between;
            .checkbox-style-heading {
              margin-right: 0;
              margin-left: 0;
            }
            span {
              padding: 0;
              &.MuiFormControlLabel-label {
                color: var(--primary-text-color);
                font-size: 14px !important;
                line-height: 14px !important;
                font-style: normal;
                font-weight: 500;
                margin-left: 8px;
                margin-top: 1px;
                white-space: nowrap;
              }
            }
            .checkbox-action {
              display: flex;
              gap: 10px;
              .action-btn {
                min-width: 30px !important;
                min-height: 30px !important;
                max-width: 30px !important;
                max-height: 30px !important;
                border-radius: 5px !important;
              }
              // }
            }
          }
        }
      }
}
