
  .setting-grid-list {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    .setting-card {
      align-items: center;
      background-color: var(--body-bg);
      border: 2px solid var(--textgrey);
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 160px;
      justify-content: center;
      width: 100%;

      &:hover {
        background-color: var(--light-grey);
      }

      .setting-icon {
        margin: 0;
        width: 65px;

        img {
          width: 100%;
        }
      }

      .setting-name {
        margin: 0;
        color: var(--primary-text-color);
      }
    }
  }

.form-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.setting-count-card-wrapper {
  .count {
    color: var(--green);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 42px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-bottom: 10px;
  }

  .title {
    color: var(--primary-text-color);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal;
  }
}

.setting-sort-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  .muilt-select-field {
    width: 200px;
  }

  .sort-by-title {
    color: var(--primary-text-color);
    text-align: right;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
  }
}

.checkbox-style-heading {
  .MuiFormControlLabel-label {
    color: var(--primary-text-color);
    // text-align: right;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
  }

  .MuiRadio-root,
  .MuiCheckbox-root {
    svg {
      fill: var(--textgrey);
    }
  }

  .MuiCheckbox-root.Mui-checked svg,
  .MuiRadio-root.Mui-checked svg {
    fill: var(--primary-text-color);
  }
}

.user-listing-tables{
  padding: 12px;
}
.checkbox-list-wrapper {
  padding: 12px;
  display: flex;
  overflow: auto;

  ul {
    padding: 0;
    padding-left: 0;
    min-height: calc(100vh - 270px);
    max-height: calc(100vh - 270px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 16px;

    li {
      width: 300px;
      padding: 0;
      padding-bottom: 16px;
      padding-right: 30px;
      padding-left: 10px;
      border-right: 1px solid #f0f0f0;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      .checkbox-style-heading {
        margin-right: 0;
        margin-left: 0;
      }
      span {
        padding: 0;
        &.MuiFormControlLabel-label {
          color: var(--primary-text-color);
          font-size: 14px !important;
          line-height: 14px !important;
          font-style: normal;
          font-weight: 500;
          margin-left: 8px;
          margin-top: 1px;
          white-space: nowrap;
        }
      }
      .checkbox-action {
        display: flex;
        gap: 10px;
        .action-btn {
          min-width: 30px !important;
          min-height: 30px !important;
          max-width: 30px !important;
          max-height: 30px !important;
          border-radius: 5px !important;
        }
        // }
      }
    }
  }
}

.tab-checkbox-wrapper{
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding-right: 24px;
  .select-all-wrapper{
    display: flex;
    gap: 16px;
    align-items: center;
    button{
      min-height: 40px !important;
      height: 40px !important;
      padding: 6px 12px !important;
    }
  }
}
.word-field-wrapper{
  margin-top: 34px;
}
.m-0{
  margin: 0 !important;
}
.right-listing-wrapper{
  margin-top: 24px !important;
}
.right-warpper{
  .content-header-right{
    justify-content: end;
    width: 100%;
  }
}
// .desktop-view-checkbox{
//   display: flex !important;
// }
.mobile-view-checkbox{
  display: none;
}
@media(max-width:650.98px){
  .desktop-view-checkbox{
    display: none !important;
  }
  .mobile-view-checkbox{
    display: flex !important;
    margin-top: 16px;
  }
  .right-warpper{
    .content-header-right{
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
      .setting-sort-wrapper{
        width: 100%;
        .muilt-select-field{
          width: 100%;
          max-width: calc(100% - 72px);
        }
      }
     
      .search-bar{
        width: 100%;
        input{
          width: 100%;
        }
      }
    }
  }
}
@media(max-width:576.98px){
  .mobile-view-checkbox{
    display: flex !important;
    margin-top: 8px;
  }
}
@media(max-width:359.98px){
  .mobile-view-checkbox{
    flex-direction: column;
    gap: 8px;
    margin-top: 0;
  }
}