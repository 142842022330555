.status {
  &.won {
    &:before {
      background-color: #f0841e;
    }
  }
  &.open {
    &:before {
      background-color: var(--green);
    }
  }
  &.lost {
    &:before {
      background-color: var(--red);
    }
  }
  &.closed {
    &:before {
      background-color: var(--convert-btn-icon);
    }
  }
  &.estimation {
    &:before {
      background-color: var(--export-btn-icon);
    }
  }
  &.estimation-provided {
    &:before {
      background-color: var(--view-btn-icon);
    }
  }
}

.side-drawer {
  .MuiDrawer-paper {
    max-width: 450px;
    width: 100%;
    .sidebar-tab-wrapper {
      &.activity-log-wrapper {
        .sidebar-tab {
          .tab-label-btn {
            padding-left: 0;
            padding-right: 0;
            min-width: 120px;
          }
          .MuiTabs-indicator {
            height: 3px;
            background-color: var(--primary-text-color);
            border-radius: 5px;
          }
        }
        .add-activity-log-btn {
          justify-content: flex-end;
          display: flex;
          width: 100%;
          button {
            margin-right: 0;
          }
        }
        .filter-content-wrapper {
          max-width: 1000px;
        }
        .activity-log-list-wrapper {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 204px);
          padding-right: 10px !important;
          overflow: auto;
          .activity-card-wrapper {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            position: relative;
            padding-bottom: 36px !important;
            &:not(:last-child) {
              &:before {
                content: '';
                width: 2px;
                height: 100%;
                position: absolute;
                left: 25px;
                background: rgba(217, 217, 217, 0.5);
                z-index: -1;
              }
            }

            .activity-log-icon {
              min-width: 50px;
              height: 50px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                width: 24px;
                height: 24px;
              }
              &.activity-create {
                background-color: #ebf1fb;
                svg {
                  path {
                    color: #2e77f2;
                  }
                }
              }
              &.activity-status {
                background-color: #f8e3e3;
                svg {
                  path {
                    color: #fa5757;
                  }
                }
              }
              &.activity-assign {
                background-color: #f3e4fc;
                svg {
                  path {
                    color: #62009d;
                  }
                }
              }
              &.activity-estimation {
                background-color: #ffecda;
                svg {
                  path {
                    color: #f0841e;
                  }
                }
              }
              &.activity-edit {
                background-color: #f1feff;
                svg {
                  path {
                    color: #41dbe7;
                  }
                }
              }
              &.activity-call {
                background-color: #e9ffbb;
                svg {
                  path {
                    color: #7cb900;
                  }
                }
              }
            }
            .activity-content-wrapper {
              display: flex;
              align-items: flex-start;
              gap: 24px;
              padding-top: 16px !important;
              width: 100%;
              @media (max-width: 768.98px) {
                flex-direction: column;
                gap: 16px;
              }
              .date-time-drawer {
                display: flex;
                .mobile-time-drawer {
                  display: none;
                  @media (max-width: 768.98px) {
                    display: flex;
                    margin-left: 4px;
                  }
                }
              }
              span {
                color: var(--textgrey);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                white-space: nowrap;
                line-height: normal;
              }
              .activity-content {
                //max-width: calc(100% - 100px);
                width: 100%;
                .activity-content-header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  h6 {
                    color: var(--primary-text-color);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                  span {
                    color: var(--textgrey);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: nowrap;
                    @media (max-width: 768.98px) {
                      display: none;
                    }
                  }
                }
                .activity-content-para {
                  border-radius: 10px;
                  background: rgba(124, 185, 0, 0.05);
                  padding: 16px !important;
                  margin-top: 18px;
                  p {
                    color: var(--primary-text-color) !important;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.rs-picker-date-menu {
  z-index: 9999 !important;
  .rs-btn.rs-btn-primary {
    background-color: var(--primary-text-color);
    color: #fff;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    background-color: var(--green);
    color: #fff !important;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: var(--primary-text-color);
  }
  .rs-calendar-time-view .rs-calendar-header-title-time {
    color: var(--primary-text-color);
  }
  .rs-calendar-time-dropdown-cell:hover,
  .rs-calendar-table-cell-content:hover {
    background-color: var(--light-green) !important;
    color: var(--priamry-text-color);
  }
}
.modal-date-picker {
  width: 100%;
  border-color: var(--border);
  border-radius: 10px;
  .rs-picker-toggle.rs-btn {
    border-radius: 10px;
    // padding-top: 9.2px !important;
    // padding-bottom: 9.2px !important;
    .rs-picker-toggle-placeholder,
    .rs-picker-toggle-textbox {
      color: rgba(35, 35, 35, 0.3);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .rs-picker-toggle-value {
      color: var(--primary-text-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &:not(.rs-picker-disabled) .rs-picker-toggle-active,
  &:hover .rs-picker-toggle {
    border-color: var(--primary-text-color) !important;
    box-shadow: unset !important;
  }
}
// .modal-call-date-time-wrapper{
//   margin: 0 !important;
//   gap: 16px;
//   width: 100% !important;
//   .date-time-wrapper{
//   }
// }
.error,
.error-message {
  color: var(--red) !important;
  font-size: 14px !important;
  margin-top: 4px;
}

.ql-toolbar.ql-snow {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 150px;
}

.ql-snow.ql-toolbar button:hover {
  background: var(--primary-text-color);
  border-radius: 5px;
  color: var(--white);
}

.ql-snow.ql-toolbar button.ql-active {
  background: var(--primary-text-color);
  border-radius: 5px;
  color: var(--white) !important;
}

.ql-snow.ql-toolbar button svg {
  color: var(--white);
}

.ql-snow.ql-toolbar button.ql-active {
  color: var(--white);
}

.ql-container.ql-snow:focus .ql-editor {
  background-color: black;
  color: var(--white);
}
.ql-editor {
  font-size: initial !important;
}

.ql-indent-1 span {
  font-size: initial !important;
}

.no-msg {
  font-size: 16px;
  font-weight: 500;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--textgrey);
}

.companyicons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--edit-btn);
}
svg {
  font-size: 20px;
}

.MuiBadge-root {
  padding-right: 4px;
}

.lead-drawer {
  .filter-button-wrapper {
    button {
      width: 100% !important;
      max-width: calc(32% - 8px) !important;
      @media (max-width: 440.98px) {
        max-width: calc(50% - 8px) !important;
      }
      &.clear-filter-tab-1 {
        max-width: 100% !important;
      }
      &.cancel-filter-btn {
        @media (max-width: 440.98px) {
          max-width: 100% !important;
        }
      }
    }
  }
  .sidebar-tab-wrapper {
    .sidebar-tab {
      .MuiTabs-scroller {
        @media (max-width: 430.98px) {
          max-width: 280px;
          overflow: auto;
        }
        @media (max-width: 390.98px) {
          max-width: 200px;
        }
      }
    }
  }
}

.lead-module-wrapper {
  flex-wrap: wrap-reverse;
}
@media (max-width: 991.98px) {
  .lead-module-wrapper {
    .filter-apply-wrapper {
      justify-content: flex-start;
    }
    .search-bar {
      width: 100%;
      max-width: calc(100% - 315px);
      input {
        width: 100%;
      }
    }
  }
}
@media (max-width: 768.98px) {
  .lead-module-wrapper {
    flex-direction: column-reverse;
  }
}
@media (max-width: 650.98px) {
  .lead-module-wrapper {
    .content-header-right {
      .search-bar {
        max-width: 100%;
      }
      button {
        width: 100%;
        max-width: calc(50% - 8px);
      }
    }
  }
}
@media (max-width: 350.98px) {
  .lead-module-wrapper {
    .content-header-right {
      button {
        max-width: 100%;
      }
    }
  }
}
.document-card-content-wrapper {
  .add-multi-input {
    @media (max-width: 500.98px) {
      flex-direction: column;
    }
    button {
      @media (max-width: 500.98px) {
        max-width: 100%;
      }
    }
  }
}

.lead-view-drawer {
  .MuiDrawer-paper {
    max-width: 900px !important;
  }
}
.view-lead-header {
  flex-wrap: wrap-reverse;
  .content-header-right {
    @media (max-width: 734.98px) {
      justify-content: center;
      width: 100%;
    }
    @media (max-width: 734.98px) {
      .attach-btn,
      .convert-btn {
        width: 100%;
        max-width: calc(50% - 28px);
      }
      .activity-btn {
        width: 100%;
        max-width: calc(100% - 60px);
      }
      .bid-btn-lead,
      .won-btn-lead {
        width: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: 590.98px) {
      .attach-btn,
      .convert-btn {
        max-width: 100%;
      }
    }
  }
}
