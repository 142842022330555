.side-drawer-2 {
  .MuiDrawer-paper {
    width: 100%;
    max-width: 450px;
    .sidebar-tab-wrapper-2 {
      .close-btn {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 14px;
        z-index: 99;

        svg {
          font-size: 20px;
          path {
            color: var(--primary-text-color);
          }
        }
      }

      .sidebar-tab {
        border-bottom: 1px solid var(--border);
        .tab-label-btn {
          opacity: 1;
          min-width: 120px;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;

          &.Mui-selected {
            color: var(--primary-text-color);
          }
        }

        .MuiTabs-indicator {
          height: 3px;
          background-color: transparent;
          border-radius: 5px;
          left: 0;
          width: 120px;
        }
      }
    }
  }
}
.modal-wrapper-announcement {
  // padding: 24px;
  max-width: 65vw;

  // width: fit-content;
  // width: fit-content;
  min-width: 650px;
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
      h3 {
          margin: 0;
      }
      p {
          color: var(--textgrey);
          font-size: 16px;
          margin: 8px 0 0 0;
          @media (max-width: 767px) {
              font-size: 14px;
          }
      }
  }
  .error {
      color: var(--red) !important;
      font-size: 14px !important;
  }
  .footer {
      margin-top: 36px;
      .btn-group {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 12px;
      }
  }
  .status-icon {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .fail-icon {
      align-self: center;
      color: var(--red);
      font-size: 52px;
  }
  .success-icon {
      align-self: center;
      color: var(--green);
      font-size: 52px;
  }
  .warning-icon {
      align-self: center;
      color: rgb(238, 255, 0);
      font-size: 52px;
  }
  .feedback-text {
      border-radius: 10px;
      padding: 20px;

      p {
          margin-bottom: 0;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-black);
      }
  }
  .fail-text {
      background: rgba(195, 90, 103, 0.2) !important;
  }
  .success-text {
      background: rgba(90, 195, 92, 0.2) !important;
  }
  .warning-text {
      background: rgba(183, 195, 90, 0.2) !important;
  }
  .confirmation-text {
      background: rgba(140, 199, 20, 0.2) !important;
      border: 1px solid var(--color-green);
      border-radius: 10px;
      padding: 20px;
  }
}
.modal-wrapper-announcement-slider {
  padding: 0 20px !important;
  max-width: 70vw;
  width: calc(100% - 32px);
  min-width: 50vw;
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 30%;
  // transform: translate(-50%, -50%) !important;
  // transform: 'revert-layer' !important;
  overflow: hidden; /* Prevent overflow */
  
  /* Ensures no horizontal scroll */
  white-space: normal; /* Allow wrapping for long text */
  word-wrap: break-word; /* Break long words to avoid overflow */
  word-break: break-word;

  .title {
      h3 {
          margin: 0;
      }
      p {
          color: var(--textgrey);
          font-size: 16px;
          margin: 8px 0 0 0;
          @media (max-width: 767px) {
              font-size: 14px;
          }
      }
  }
  
  .error {
      color: var(--red) !important;
      font-size: 14px !important;
  }
  
  .footer {
      margin-top: 36px;
      .btn-group {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 12px;
      }
  }

  .status-icon {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .fail-icon, .success-icon, .warning-icon {
      align-self: center;
      font-size: 52px;
  }
  
  .fail-icon {
      color: var(--red);
  }

  .success-icon {
      color: var(--green);
  }

  .warning-icon {
      color: rgb(238, 255, 0);
  }
  
  .feedback-text {
      border-radius: 10px;
      padding: 20px;
      
      p {
          margin-bottom: 0;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-black);
      }
  }
  
  .fail-text {
      background: rgba(195, 90, 103, 0.2) !important;
  }

  .success-text {
      background: rgba(90, 195, 92, 0.2) !important;
  }

  .warning-text {
      background: rgba(183, 195, 90, 0.2) !important;
  }

  .confirmation-text {
      background: rgba(140, 199, 20, 0.2) !important;
      border: 1px solid var(--color-green);
      border-radius: 10px;
      padding: 20px;
  }
}
.ann-date-range .rs-picker-toggle.rs-btn{
  height: 40px !important;
}
// .select-date-range span{
//   color:black !important;
// }
@media (max-width: 680px) {
  .modal-wrapper-announcement{
      min-width: 95vw;
      max-width: 95vw;
  }
}
@media (max-width: 900px) {
  .modal-wrapper-announcement-slider{
      min-width: 85vw;
      max-width: 85vw;
  }
}
@media (max-width: 456.98px) {
 .modal-wrapper-announcement-slider {
  .modal-body{
     padding:24px 0px !important;
  }
}
}
.slick-prev {
  // left: 3% !important;
  z-index: 1;
  top: 42% !important;
  left:-32px !important;
}
.slick-next {
  // right: 3% !important;
  z-index: 1;
  top: 42% !important;
  right: -32px !important;
}
.slick-dots{
  position: relative !important;
}

.modal-ui {
  padding: 0;
  .modal-body {
    padding: 24px;
  }
}

.announcement-contents {
  margin: 24px 32px 0px 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  h2 {
    margin: 0;
  }
}

.announcement-drawer {
  .MuiDrawer-paper {
      max-width: 540px !important;
  }
}

@media(max-width:991.98px){
  .announcement-contents {
      margin: 24px 16px 0px 16px;
  }
}


.announcement-title {
  cursor: default;
  padding-bottom: 8px;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #7cb900 !important;
}

.announcement-desc {
  display: flex;
  flex-direction: column;
  cursor: default;
  max-height: 420px;
  overflow: auto;
  margin-top: 8px;
  padding: 10px;
  font-size: 15px !important;
  font-weight: 400 !important;
  p {
    color: black !important;
  }
}
.announcement-date {
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
}

.announcement-marque {
  width: 100%;
  background-color: rgba(124, 185, 0, 0.1);
  padding: 10px 16px;
  border-radius: 12px;
  // margin-bottom: 16px;
  overflow: hidden;
  border-color: rgb(124, 185, 0) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: { xs: row; sm: row };

  .marque-list {
    display: inline-block;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }
  .marque-text {
    font-weight: semibold;
    color: rgb(35, 35, 35) !important;
    cursor: pointer;
  }
}
.joiningdate {
  .rs-picker-toggle-wrapper {
    border-radius: 10px;
    input {
      border-radius: 10px;
    }
  }
}
.drawer-container {
  .announcement-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
  }
  .tabs-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icons {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }
}
.announcement-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.announcement-section {
  // border: 2px solid red;
  padding: 10px;
  padding-right: 0;
}
.announcement-item {
  // border: 3px solid yellow;
  padding: 10px 10px;
  &:hover {
    background-color: rgba(234, 234, 234, 0.384);
    border-radius: 15px;
  }
  .announcement-content {
    width: 100%;

    .subject {
      font-size: 15px;
      padding-right: 15px;
    }
    .details {
      font-size: 14px;
      color: gray !important;
      padding-right: 15px;
    }
    .created-at {
      display: flex;
      justify-content: space-between;
      // border: 2px solid green;
      width: 100%;
      span {
        color: rgb(145, 145, 145);
      }
    }
  }
}
.announcement-module-wrapper {
  flex-wrap: wrap-reverse;
  gap: 24px;
}
@media (max-width: 900.98px) {
  .announcement-module-wrapper .content-header-right .search-bar input {
    width: 268px;
  }
}
@media (max-width: 900.98px) {
  .announcement-module-wrapper .content-header-right {
    width: 60%;
  }
}
@media (max-width: 701px) {
  .announcement-module-wrapper .content-header-right {
    width: 100%;
  }
}
@media (max-width: 900.98px) {
  .announcement-module-wrapper .content-header-right .search-bar {
    flex: 1 1;
    width: 100%;
  }
}
@media (max-width: 900.98px) {
  .announcement-module-wrapper .content-header-right .search-bar input {
    width: 100%;
  }
}
@media (max-width: 700.98px) {
  .announcement-module-wrapper .content-header-right .search-bar {
      width: 100%;
  }
}
@media (max-width: 700.98px) {
  .announcement-module-wrapper .content-header-right .primary-btn {
    max-width: 100%;
    width: 100%;
    // flex: 1 1;
  }
}
@media (max-width: 700.98px) {
  .announcement-module-wrapper .content-header-right .search-bar input {
      max-width: 100%;
      width: 100%;
      // flex: 1 1;
  }
}

@media (max-width: 900.98px) {
  .announcement-module-wrapper {
    flex-direction: row;
  }
}
// @media (max-width: 768px) {
//   .content-wrap {
//     padding: 0 31px 0 24px !important;
//   }
// }
@media (max-width: 414.98px){
  .announcement-icon{
    margin-left: 14px;
  }
}