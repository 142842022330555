@import "rsuite/dist/rsuite.css";

// Color Variables //
:root {
  --primary-text-color: #232323;
  --white: #ffffff;
  --secondary-text-color: #f0f0f0;
  --textgrey: #a5a5a5;
  --green: #7cb900;
  --red: #ee5e5e;
  --black: #323232;
  --grey: #777777;
  --light-green: #ebf5d9;
  --body-bg: #f4f4f4;
  --light-grey: #f0f0f0;

  --primary-btn-hover: #474747;
  --secondary-btn-hover: #e0e0e0;
  --green-btn-hover: #86cd05;

  --view-btn: #edf8f5;
  --view-btn-icon: #4cbc9a;
  --view-btn-hover: #def5ef;

  --edit-btn: #e9e9e9;
  --edit-btn-hover: #e2dede;

  --delete-btn: #ffeff0;
  --delete-btn-icon: #ff606b;
  --delete-btn-hover: #fbdfe1;

  --fire-btn: #fceed7;
  --fire-btn-icon: #fc9209;
  --fire-btn-hover: #fde3c1;

  --export-btn: #ebf4fa;
  --export-btn-icon: #3eabcd;
  --export-btn-hover: #d1e7f7;

  --save-btn: #e9e9e9;
  --save-btn-hover: #e2dede;

  --convert-btn: rgba(85, 76, 188, 0.1);
  --convert-btn-icon: #574cd5;
  --convert-btn-hover: rgba(85, 76, 188, 0.2);
  --convert-btn: rgba(85, 76, 188, 0.1);
  --convert-btn-icon: #574cd5;
  --convert-btn-hover: rgba(85, 76, 188, 0.2);

  --scrollbar-thumb: #d9d9d9;
  --border: #dddddd;
  --scrollbar-thumb: #d9d9d9;
  --border: #dddddd;
}
.text-green {
  color: var(--green) !important;
}

.text-black {
  color: var(--black) !important;
}

.text-white {
  color: var(--white);
}

.text-grey {
  color: var(--grey) !important;
}
// Font Faces //
@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-SemiBold.eot");
  src: url("../assets/fonts/Mona-Sans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-SemiBold.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-SemiBold.svg#Mona-Sans-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Regular.eot");
  src: url("../assets/fonts/Mona-Sans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Regular.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Regular.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Regular.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Regular.svg#Mona-Sans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Medium.eot");
  src: url("../assets/fonts/Mona-Sans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Medium.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Medium.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Medium.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Medium.svg#Mona-Sans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Bold.eot");
  src: url("../assets/fonts/Mona-Sans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Bold.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Bold.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Bold.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Bold.svg#Mona-Sans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Light.eot");
  src: url("../assets/fonts/Mona-Sans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Light.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Light.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Light.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Light.svg#Mona-Sans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  background-color: transparent;
  border-radius: 6;
}

::-webkit-scrollbar-thumb {
  border-radius: 6;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-thumb);
}

body {
  margin: 0;
  background-color: var(--body-bg) !important;
}
* {
  font-family: "Mona-Sans" !important;
  color: var(--primary-text-color);
}
h1 {
  font-size: 26px !important;
  font-weight: bold !important;
}
h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
}
h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
}
h4 {
  font-size: 18px !important;
  font-weight: 500 !important;
}
h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
p {
  color: var(--textgrey) !important;
}
img {
  max-width: 100%;
}
video {
  max-width: 100%;
}
.h-100 {
  height: 100%;
}
.p-24 {
  padding: 24px;
}
// Navbar Styles //
.navbar {
  // border: 4px solid red;
  width: 100%;
  position: sticky !important;
  z-index: 10 !important;
  background-color: var(--white) !important;
  .MuiToolbar-regular {
    min-height: 64px;
  }
  .small-logo {
    display: none;
  }

  &.sidebar-closed {
    div {
      .navbar-left {
        // border: 3px solid yellow;
        gap: 0;
        // min-width: 80px;
        min-width: 0;
        width: 0;
        padding-left: 0;
        padding-right: 0;
        .small-logo {
          display: block;
          width: 36.5px;
          position: relative;
          left: 10px;
        }
        .large-logo {
          display: none;
        }
        .toggle-btn {
          background-color: var(--green);
          position: fixed;
          left: 45px;
          width: 30px;
          height: 30px;
          padding: 0;
          top: 16px;
          @media (max-width: 991.98px) {
            left: 26px;
          }
          .open-menu {
            display: none;
          }
          .close-menu {
            display: block;
          }
        }
      }
      .navbar-right {
        padding-left: 45px;
        width: 100%;
        @media (max-width: 991.98px) {
          padding-left: 26px;
        }
      }
    }
  }
  .avatar-ui {
    border-radius: 10px;
  }

  div {
    padding: 0;

    .navbar-left {
      // border: 3px solid yellow;
      background-color: var(--primary-text-color);
      border-bottom: 1px solid var(--primary-text-color) !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
      min-width: 300px;
      padding: 13px 8px;
      box-sizing: border-box;
      transition: all 0.5s;
      -webkit-transition: all 0.25s;
      height: 64px;
      padding-right: 6px;
      img {
        width: 144px;
        position: relative;
        left: 10px;
      }
      svg {
        fill: var(--white);
        font-size: 24px;
      }
      .toggle-btn {
        .close-menu {
          display: none;
        }
      }
    }

    .navbar-right {
      margin: 0 32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 991.98px) {
        margin-right: 16px;
      }
      .user-info {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        font-weight: 500;
        button {
          text-transform: capitalize;
          color: var(--primary-text-color);
          font-size: 16px;
          font-weight: 500;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
// Navbar Styles END //

// Sidebar Styles //
.sidebar-wrapper {
  z-index: 15 !important;
  .logo-box {
    gap: 0;
    padding: 10px;
    padding-left: 18px;
    padding-right: 0;
    .small-logo {
      display: block;
      width: 36.5px;
      position: relative;
      left: 10px;
    }
    .large-logo {
      width: auto;
      height: 37px;
    }
    .toggle-btn {
      position: relative;
      left: 100px;
      @media (max-width: 991.98px) {
        left: 26px;
      }
      .open-menu {
        font-size: 25px;
      }
      .close-menu {
        display: block;
      }
    }
  }
  &.sidebar-menu-closed {
    .MuiDrawer-paper {
      transform: none !important;
      visibility: unset !important;
      overflow: hidden;
      .list-wrap {
        width: 0;
        .menu-text-wrap {
          height: 24px;
          span {
            display: none;
          }
        }
      }
    }
  }
  &.sidebar-menu-open {
    .MuiDrawer-paper {
      border: 0;
    }
  }
  .MuiDrawer-paper {
    transition: all 0.5s !important;
    -webkit-transition: all 0.25s !important;
    overflow: hidden;
  }
  .list-wrap {
    height: 100vh;
    background-color: var(--primary-text-color);
    // padding-top: 78px !important;
    padding-bottom: 16px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: all 0.5s;
    -webkit-transition: all 0.25s;
    .logo-box {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      height: 64px;
      padding: 12px 18px;
    }
    ul.sidebar {
      display: flex;
      flex-direction: column;
      gap: 9px;
      padding-top: 20px;
      li {
        padding: 0 4px;
        .icon-wrap {
          min-width: 35px !important;
          svg {
            fill: var(--textgrey);
            font-size: 25px;
            path {
              color: var(--textgrey);
            }
          }
        }
        span {
          color: var(--textgrey);
        }
        &:hover {
          svg {
            fill: var(--green);
          }
          span {
            color: var(--white);
          }
        }
      }
    }
    li.activemenu {
      .icon-wrap {
        svg {
          fill: var(--green) !important;
          path {
            color: var(--green) !important;
          }
        }
      }
      span {
        color: var(--white) !important;
      }
    }
  }
}
// Sidebar styles END //

// Content Layout //
.content-header {
  margin: 32px 32px 0px 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 991.98px) {
    margin: 32px 16px 0px 16px;
  }
  h2 {
    margin: 0;
  }
}
.content-layout {
  border-radius: 12px;
  background-color: var(--white);
  margin: 24px 32px 32px 32px;
  @media (max-width: 991.98px) {
    margin: 24px 16px 24px 16px;
  }
  h3 {
    margin-top: 0px;
  }
}

.main-content {
  margin-left: 300px;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
  // padding-bottom: 75px;
  padding-bottom: 10px;
  &:has(.footer-wrapper) {
    padding-bottom: 75px !important;
  }
  &.remove-bottom-space {
    padding-bottom: 0;
  }
}
main.main-content.full-width {
  // margin-left: 80px;
  margin-left: 0;
}
.pattern-big,
.pattern-small {
  position: absolute;
  z-index: -1;
}
.pattern-big {
  top: 0;
  left: 0;
}
.pattern-small {
  right: 0;
  bottom: 0;
}

// Content Layout END //

// Common Styles //
.d-none {
  display: none !important;
}
.w-120 {
  min-width: 120px !important;
}
.btn {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  padding: 8px 16px !important;
}
.primary-btn {
  background-color: var(--primary-text-color) !important;
  color: var(--white) !important;
  &:hover {
    background-color: var(--primary-btn-hover) !important;
  }
  &:disabled {
    background-color: var(--light-grey);
    -webkit-text-fill-color: black;
  }
}

.primary-btn {
  p {
    margin: 0;
    line-height: 21px;
    vertical-align: middle;
    color: var(--white) !important;
  }
}
.secondary-btn {
  background-color: var(--secondary-text-color) !important;
  color: var(--primary-text-color) !important;
  &:hover {
    background-color: var(--secondary-btn-hover) !important;
  }
}
.link-btn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  color: var(--primary-text-color) !important;
  text-decoration: underline !important;
  margin: 0 !important;
  &:hover {
    background-color: transparent !important;
  }
}
.outline-btn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  color: var(--primary-text-color) !important;
  background-color: transparent !important;
  border: 1px solid var(--primary-text-color) !important;
  &:hover {
    background-color: var(--secondary-text-color) !important;
  }
}
.green-btn {
  background-color: var(--green) !important;
  color: var(--white) !important;
  &:hover {
    background-color: var(--green-btn-hover) !important;
  }
}

.action-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 40px !important;
  height: 40px;
  padding: 0 !important;
  border: none !important;
  border-radius: 10px !important;
  span {
    margin: 0;
  }
}
.add-btn {
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 13px;
    svg {
      fill: var(--white);
    }
    &.filter-counte {
      border-radius: 7px;
      background-color: var(--white);
      color: var(--primary-text-color);
      font-size: 13px;
      line-height: 23px !important;
      height: 20px;
      margin-right: 8px;
      margin-left: 0;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.only-icon-btn {
  width: 42.5px !important;
  height: 42.5px;
  min-width: unset !important;
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 13px;
    margin: 0;
    svg {
      fill: var(--white);
    }
  }
}
label {
  color: var(--primary-text-color) !important;
  font-size: 13px !important;
  // margin-bottom: 4px !important;
  display: flex;
  font-weight: 700 !important;
}
label span.asterisk {
  color: var(--red) !important;
  font-size: 18px;
  line-height: 18px;
}
.value {
  color: var(--primary-text-color) !important;
  margin: 0;
  font-size: 16px;
}
.MuiModal-root {
  z-index: 9999;
}
.MuiRadio-root,
.MuiCheckbox-root {
  svg {
    fill: var(--textgrey);
  }
}
.MuiRadio-root.Mui-checked,
.MuiCheckbox-root.Mui-checked {
  svg {
    fill: var(--primary-text-color);
  }
}
.MuiInputBase-input.MuiOutlinedInput-input,
.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 14px;
  height: 40px;
  box-sizing: border-box;
  &:disabled {
    background-color: var(--light-grey);
    -webkit-text-fill-color: black;
  }
}
.title-with-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  button.back-btn {
    background-color: transparent !important;
    svg {
      font-size: 30px;
    }
  }
}

.MuiModal-root {
  .MuiModal-backdrop {
    backdrop-filter: blur(5px);
  }
}
.MuiPopover-root {
  .MuiModal-backdrop {
    backdrop-filter: inherit;
  }
}
.modal-wrapper {
  padding: 34px;
  max-width: 610px;
  width: calc(100% - 24px);
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    h3 {
      margin: 0;
    }
    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .error {
    color: var(--red) !important;
    font-size: 14px !important;
  }
  .footer {
    margin-top: 36px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      button {
        min-width: 120px;
      }
    }
  }
  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fail-icon {
    align-self: center;
    color: var(--red);
    font-size: 52px;
  }
  .success-icon {
    align-self: center;
    color: var(--green);
    font-size: 52px;
  }
  .warning-icon {
    align-self: center;
    color: rgb(238, 255, 0);
    font-size: 52px;
  }
  .feedback-text {
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }
  }
  .fail-text {
    background: rgba(195, 90, 103, 0.2) !important;
  }
  .success-text {
    background: rgba(90, 195, 92, 0.2) !important;
  }
  .warning-text {
    background: rgba(183, 195, 90, 0.2) !important;
  }
  .confirmation-text {
    background: rgba(140, 199, 20, 0.2) !important;
    border: 1px solid var(--color-green);
    border-radius: 10px;
    padding: 20px;
  }
}
.modal-wrapper-change {
  padding: 34px;
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin: 24px 32px 32px 32px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  @media only screen and (min-device-width: 480px) and (max-device-width: 740px) and (orientation: landscape) {
    position: relative;
    margin-top: 56px;
  }
  @media (max-width: 480px) {
    width: 80%;
    margin: 24px auto !important;
  }
  .title {
    h3 {
      margin: 0;
    }
    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .error {
    color: var(--red) !important;
    font-size: 14px !important;
  }
  .footer {
    margin-top: 48px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
.keyword-modal {
  padding: 34px 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  .title {
    h3 {
      margin: 0;
    }
    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
    }
  }
  .footer {
    margin-top: 48px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 34px;
      gap: 12px;
      .btn {
        min-width: 120px;
      }
    }
  }
}
.action-btn.view-btn {
  background-color: var(--view-btn);
  span {
    svg {
      fill: var(--view-btn-icon);
    }
  }
  &:hover {
    background-color: var(--view-btn-hover);
  }
}
.action-btn.edit-btn {
  background-color: var(--edit-btn);
  span {
    svg {
      fill: var(--primary-text-color);
    }
  }
  &:hover {
    background-color: var(--edit-btn-hover);
  }
}
.action-btn.dlt-btn {
  background-color: var(--delete-btn);

  span {
    svg {
      fill: var(--delete-btn-icon);
    }
  }
  &:hover {
    background-color: var(--delete-btn-hover);
  }
}
.action-btn.fire-btn {
  background-color: var(--fire-btn);

  span {
    svg {
      fill: var(--fire-btn-icon);
    }
  }
  &:hover {
    background-color: var(--fire-btn-hover);
  }
}
.action-btn.export-btn {
  background-color: var(--export-btn);

  span {
    svg {
      fill: var(--export-btn-icon);
    }
  }
  &:hover {
    background-color: var(--export-btn-hover);
  }
}
.action-btn.convert-btn {
  background-color: var(--convert-btn);

  span {
    svg {
      fill: var(--convert-btn-icon);
    }
  }
  &:hover {
    background-color: var(--convert-btn-hover);
  }
  &:disabled {
    background-color: var(--export-btn);
    opacity: 0.6;
  }
}
.action-btn.save-btn {
  background-color: var(--save-btn);

  span {
    svg {
      fill: var(--primary-text-color);
    }
  }
  &:hover {
    background-color: var(--save-btn-hover);
  }
}
.action-btn.cross-btn {
  background-color: var(--delete-btn);

  span {
    svg {
      fill: var(--delete-btn-icon);
    }
  }
  &:hover {
    background-color: var(--delete-btn-hover);
  }
}
.action-btn.upload-btn {
  background-color: transparent;
  border: 1px solid var(--primary-text-color) !important;
  height: 40px;
  width: 120px;
  width: 120px;
  color: var(--primary-text-color) !important;
  line-height: 40px;
  span {
    color: var(--primary-text-color) !important;
    svg {
      fill: var(--export-btn-icon);
    }
  }
  &:hover {
    background-color: var(--primary-text-color);
    color: var(--white) !important;
    span {
      span {
        color: var(--primary-text-color) !important;
      }
    }
  }
}
.action-btn.navigate-btn {
  &:disabled {
    background-color: var(--white);
    opacity: 0.6;
  }
}
.user-listing {
  .tab-area {
    button {
      span.user-name {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
      }
    }
    button.Mui-selected {
      span.role-name {
        color: var(--primary-text-color);
      }
    }
    .MuiTabs-indicator {
      background-color: var(--green);
    }
    .MuiTabs-flexContainer {
      .MuiTabs-flexContainer {
        overflow: auto;
        // @media(max-width:768.98px){
        //   padding-bottom: 10px;
        // }
      }
      // .MuiTabs-indicator{
      //   @media(max-width:768.98px){
      //     bottom: 10px;
      //   }
      // }
    }
    .user-listing-tables {
      > .MuiBox-root {
        padding-top: 12px !important;
      }
    }
  }
}

.custom-auto {
  .MuiTabs-fixed {
    overflow: auto !important;
  }

  button {
    span.user-name {
      text-transform: capitalize;
      font-size: 16px;
    }
  }
  button.Mui-selected {
    span.role-name {
      color: var(--primary-text-color);
    }
  }
  .MuiTabs-indicator {
    background-color: var(--green);
  }
  .MuiTabs-flexContainer {
    .MuiTabs-flexContainer {
      overflow: auto;
      // @media(max-width:768.98px){
      //   padding-bottom: 10px;
      // }
    }
  }
}
.content-header-right {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  .search-bar {
    position: relative;
    @media (max-width: 575px) {
      width: 100%;
      margin-bottom: 8px;
    }
    svg {
      position: absolute;
      top: 10px;
      left: 8px;
      font-size: 18px;
      z-index: 2;
    }
    .cross-line {
      position: absolute;
      top: 10px;
      left: 90%;
      font-size: 18px;
      z-index: 2;
      cursor: pointer;
      fill: #b7b7b7 !important;
    }
    input {
      width: 260px;
      background-color: var(--white);
      padding-left: 32px !important;
      box-shadow: 0px 4px 4px 0px var(--secondary-text-color);
      border-radius: 10px !important;
      border-color: var(--textgrey) !important;
    }
    fieldset {
      border-radius: 10px !important;
      border: none !important;
    }
  }
}

.input-field {
  .MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: var(--border) !important;
    &:focus-visible {
      outline: none !important;
    }
    &.Mui-focused {
      fieldset {
        border-width: 1px !important;
        border-color: var(--primary-btn-hover) !important;
      }
    }
  }
}

.muilt-select-field {
  .select__placeholder {
    color: rgba(35, 35, 35, 0.3);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .select__menu {
    z-index: 9;
  }
}
.textarea-field {
  // width: calc(100% - 34px);
  width: 100%;
  padding: 16px;
  min-height: 142px;
  border-radius: 8px;
  border-color: var(--border);
  border-width: 1px !important;
  box-shadow: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &::-webkit-input-placeholder {
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &::-moz-placeholder {
    /* Firefox 18- */
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:-ms-input-placeholder {
    // color: rgba(35, 35, 35, 0.30);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.modal-textarea {
    min-height: 100px;
  }
}
.textarea-field-small {
  // width: calc(100% - 34px);
  width: 100%;
  padding: 16px;
  min-height: 60px;
  border-radius: 8px;
  border-color: var(--border);
  border-width: 1px !important;
  box-shadow: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &::-webkit-input-placeholder {
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &::-moz-placeholder {
    /* Firefox 18- */
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:-ms-input-placeholder {
    // color: rgba(35, 35, 35, 0.30);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.modal-textarea {
    min-height: 100px;
  }
}
.footer-wrapper {
  border-top: 1px solid var(--border);
  background: var(--white);
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 0;
  left: 0;
  .secondary-btn {
    margin-right: 32px;
  }
}
// Common Styles END //

// Table //
Table.listing-table {
  thead {
    tr {
      th {
        font-weight: 700;
        white-space: nowrap;
      }
      th.th-role-name {
        width: 80%;
      }
      th.th-action {
        width: 20%;
      }
    }
  }
  .action-btn-wrap {
    display: flex;
    gap: 16px;
  }
  .table-action-btn-wrap {
    display: flex;
    gap: 16px;
  }
  tbody {
    position: relative;
    .table-no-data {
      width: 100%;
      display: block;
      text-align: center;
      padding: 40px 0;
      position: absolute;
      background-color: var(--white);
      height: 450px;
      margin-top: -6px;
    }
    tr {
      td {
        color: var(--primary-text-color);
        //white-space: nowrap;
        .project-desc {
          color: rgba(35, 35, 35, 0.8);
          width: 250px;
          .project-desc {
            color: rgba(35, 35, 35, 0.8);
            width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
        .add-space {
          padding: 0px 34px !important;
        }
      }
      .align-right {
        justify-content: flex-end;
      }
    }
  }
}
.app-name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.table-no-data-tbody {
  position: relative;
  background-color: var(--white);
  height: 500px;
}
.table-no-data {
  width: 100%;
  display: block;
  text-align: center;
  margin: 40px 0;
  position: absolute;
  img {
    width: 160px;
  }
  h5 {
    margin: 0 0 16px 0;
  }
  P {
    margin: 4px;
  }
  button {
    margin-top: 24px;
  }
}
.pagination-wrapper {
  text-align: center;
  margin: 0 24px;
  nav {
    margin: 32px 0;
    ul {
      justify-content: center;
      li {
        button {
          font-size: 15px;
        }
        button.Mui-selected {
          background-color: var(--edit-btn);
        }
      }
    }
  }
}
// Table END//

.app-logo {
  margin: 40px 0;
  .logo-image-wrap {
    display: inline-flex;
    position: relative;
    .logo-image {
      background-color: var(--secondary-text-color);
      border-radius: 10px;
      height: 120px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button.upld-btn {
      position: absolute;
      right: -10px;
      bottom: -5px;
      height: 32px !important;
      background-color: var(--primary-text-color);
      border-radius: 50%;
      min-width: 32px !important;
      svg {
        fill: var(--white);
        font-size: 16px;
      }
    }
  }
}

.role-info {
  .tab-wrapper {
    button {
      span.role-name {
        text-transform: capitalize;
        margin-right: 8px;
        color: var(--textgrey);
      }
      span.module-count {
        border-radius: 10px;
        border: 1px solid var(--textgrey);
        background: var(--secondary-text-color);
        font-size: 12px;
        color: var(--primary-text-color);
        width: 26px;
        height: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 12px;
      }
    }
    button.Mui-selected {
      span.role-name {
        color: var(--primary-text-color);
      }
      span.module-count {
        border: 1px solid var(--textgrey);
        background: var(--light-green);
      }
    }
    .MuiTabs-indicator {
      background-color: var(--green);
    }
  }
}
.role-list-wrapper {
  border: 1px solid var(--secondary-text-color);
  border-radius: 8px;
  tr {
    td {
      p {
        color: var(--primary-text-color) !important;
      }
    }
  }
}
.footer-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;
  .btn-group {
    gap: 16px;
    display: flex;
  }
}

.permission-button {
  button {
    padding: 4px 16px;
    text-transform: capitalize;
    border-radius: 10px;
  }
  button.Mui-selected {
    background-color: var(--light-green) !important;
  }
}
.create-role-wrap {
  .role-detail {
    margin-bottom: 32px;
  }
}
.app-list {
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    margin: 0;
    color: var(--primary-text-color) !important;
    font-weight: 500;
    white-space: nowrap;
    span {
      font-weight: 500;
    }
  }
}

.tag-wrap {
  display: flex;
  gap: 8px;
  .MuiChip-root {
    background-color: var(--light-green);
    text-transform: capitalize;
    color: var(--primary-text-color);
    border-radius: 4px;
    padding: 4px 0px;
    white-space: nowrap;
    font-weight: 500;
    &:hover {
      background-color: var(--light-green);
    }
  }
}

.app-list {
  p {
    color: var(--primary-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}
.app-details {
  .app-details-table {
    border-radius: 8px;

    .role-column {
      padding-left: 0 !important;
    }
    .header {
      border-bottom: 1px solid var(--secondary-text-color);
      padding: 8px 16px;
      margin: 0 !important;
    }
    .row {
      border-bottom: 1px solid var(--secondary-text-color);
      padding: 16px;
      p {
        margin: 0;
      }
    }
  }
}

// Login screen //
.login-main-wrap {
  background-color: var(--primary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  gap: 40px;
  .logo-wrap {
    text-align: center;
    img {
      width: 250px;
    }
    h2 {
      margin: 12px 0 0 0;
      color: var(--white);
    }
  }
  .login-form-wrap {
    background-color: var(--white) !important;
    border-radius: 12px;
    padding: 32px;
    position: relative;
    z-index: 2;
    .title {
      text-align: center;
      margin-bottom: 24px;
      h3 {
        margin: 0;
      }
      p {
        margin: 12px 0 0 0 !important;
      }
    }
    button.primary-btn {
      width: 100%;
      margin-top: 24px;
    }
    .forgot-pw {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;
      .link-btn {
        margin-top: 8px !important;
        padding: 0 !important;
      }
    }
  }
  .pattern-big,
  .pattern-small {
    z-index: 1;
  }
  .pattern-big {
    top: -50px;
    left: -170px;
  }
}
// Login screen END //

// Non-Admin Dashboard//
.app-grid-list-scroll {
  height: calc(100vh - 123px);

  .app-grid-list {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    .app-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      border: 2px solid var(--textgrey);
      background-color: var(--body-bg);
      border-radius: 10px;
      width: 18%;
      height: 160px;
      cursor: pointer;
      &:hover {
        background-color: var(--secondary-text-color);
      }
      .app-logo {
        margin: 0;
      }
      h5.app-name {
        font-size: 18px;
        color: var(--primary-text-color);
        margin: 0;
      }
    }
  }
}
// Non-Admin Dashboard END//

// Non Admin User Profile//
.profile-detail-wrap {
  .profile-view {
    display: flex;
    .profile-picture {
      width: 22%;
      .image-wrap {
        display: inline-flex;
        position: relative;
        .image {
          background-color: var(--secondary-text-color);
          border: 2px solid var(--primary-text-color);
          border-radius: 10px;
          height: 130px;
          width: 130px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        button.upld-btn {
          position: absolute;
          right: 5px;
          bottom: 8px;
          height: 30px !important;
          min-width: 30px !important;
          background-color: var(--primary-text-color);
          border-radius: 50%;

          svg {
            fill: var(--white);
            font-size: 16px;
          }
        }
      }
      .btn-wrap {
        width: 100%;
        margin-top: 16px;
        .outline-btn {
          margin-top: 16px;
        }
      }
    }
    .profile-detail {
      width: 38%;
      margin-right: 32px;
      .btn-wrap {
        .outline-btn {
          margin-top: 16px;
        }
      }
    }
    .app-details {
      width: 40%;
      h3 {
        span {
          font-size: 16px !important;
        }
      }
    }
  }
}
// Non Admin User Profile END//

.page-not-found {
  height: 100vh;
  .page-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    img {
      max-width: 100%;
    }
    .page-404 {
      max-width: 35%;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 36px !important;
      margin-bottom: 8px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
    p {
      font-size: 20px;
      margin-bottom: 24px;
      color: var(--primary-btn-hover) !important;
    }
  }
}

.no-msg {
  font-size: 16px;
  font-weight: 500;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.error,
.error-message {
  color: var(--red) !important;
  font-size: 14px !important;
  margin-top: 4px;
}

.no-msg-found {
  height: 30px;
  color: var(--textgrey) !important;
  text-align: center;
}

.value1 {
  color: var(--green) !important;
}

.error-message-login {
  color: var(--red) !important;
  font-size: 14px !important;
  margin-left: -14px;
}

.user-listing-tables {
  > .MuiBox-root {
    padding-top: 0px !important;
  }
}

.modal-date-picker .rs-picker-toggle.rs-btn {
  z-index: 0;
}

.rs-picker-daterange-menu {
  z-index: 1111119 !important;
  width: 408px;
  // border: 2px solid red;
  @media (max-width: 480.98px) {
    // border: 2px solid blue;
    width: fit-content;
  }
}

.rs-stack {
  width: 350px;
  display: block;
}

.rs-calendar {
  max-width: 420px;
}

.rs-picker-daterange-content {
  border-right: 1px solid #e5e5ea !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #7cb900;
}

.rs-calendar-table-cell-in-range::before {
  background-color: var(--light-green);
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--primary-text-color);
}

.rs-btn-primary {
  background-color: #7cb900;
  // text-align: end;
  width: 130px;
  background-color: #8cc714 !important;

  color: #fff !important;

  top: 5px;
  // width: 171px;
}

.rs-btn-primary:hover {
  background-color: #7cb900;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  background-color: var(--green) !important;
}

.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-table-cell-content:hover {
  background-color: var(--light-green) !important;
  color: var(--priamry-text-color);
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 12px;
  height: 45px;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 12px;
  right: 12px;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: rgba(35, 35, 35, 0.3);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
  background-color: #7cb900;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #7cb900;
}

.rs-picker-toolbar-right {
  text-align: end;
}

.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar {
  // max-width: 100%;
}

.rs-picker-daterange-header {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.release-note {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  padding: 15px;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.version-text {
  color: var(--primary-text-color) !important;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.title-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 28px;
  justify-content: flex-start;
}

.date-text {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.down-arrow {
  color: var(--color-grey);
  cursor: pointer;
  font-size: 32px;
  margin-right: 42px;
}

.release-action-container {
  align-items: center;
  margin-left: auto;
}

.rotate-transition {
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.description-content {
  background-color: #f9f9f9;
  margin-top: 20px;

  .ql-container {
    p {
      color: var(--black) !important;
    }
  }
}
.user-profile {
  // border: 2px solid var(--green);
}
.release-button {
  align-items: center;
  background-color: #232323 !important;
  border: 2px solid var(--green);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-left: auto;
  width: 36px;
  @media (max-width: 768.98px) {
    width: 32px;
    height: 32px;
  }
}

.release-icon {
  color: #8cc714;
  fill: var(--green) !important;
}

.load-more-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 40px;

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
  }
  .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #000;
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  height: 40px;
  border-radius: 10px;
  padding: 0px 9px 0px 9px !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  display: none;
}

.MuiAutocomplete-hasClearIcon {
  color: pink !important;
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.rs-stack-item {
  display: flex;
  // width: 130px;
}

.rs-stack-item .rs-btn-link {
  border: 1px solid #8cc714 !important;

  border-radius: 5px;
  color: #323232 !important;
  color: var(--color-black) !important;
  text-align: center;
  text-decoration: none !important;
  width: 130px;
}

.rs-stack-item .rs-btn-link:hover {
  background-color: var(--light-green);
}

.rs-picker-toolbar-ranges {
  align-items: flex-start !important;
  flex-wrap: wrap;
  gap: 4px !important;
}

.rs-picker-toolbar .rs-stack {
  align-items: flex-start;
  justify-content: space-between;
}

.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column !important;
  display: flex;
}

.action-btn-wrap {
  display: flex;
  gap: 16px;
}

fieldset {
  border-color: var(--border) !important;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMultiSectionDigitalClockSection-item {
  /* Your styles go here */
  background-color: black;
  font-size: 16px;
  padding: 2px;
  /* Add more styles as needed */
}

.cross-line-icon {
  position: relative;
  @media (max-width: 575px) {
    width: 100%;
    margin-bottom: 8px;
  }
  svg {
    position: absolute;
    top: 10px;
    left: 8px;
    font-size: 18px;
    z-index: 2;
  }
  .cross-line {
    position: absolute;
    top: 10px;
    left: 93%;
    font-size: 18px;
    z-index: 2;
    cursor: pointer;
    fill: #b7b7b7 !important;
  }
}

.hidden {
  display: none;
}

.bids-module-wrapper {
  @media (max-width: 930.98px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    .content-header-right {
      justify-content: flex-start !important;
      .search-bar {
        max-width: calc(100% - 275px);
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
  .content-header-right {
    .search-bar {
      @media (max-width: 650.98px) {
        max-width: 100%;
      }
    }
    button {
      @media (max-width: 650.98px) {
        width: 100%;
        max-width: calc(50% - 8px);
      }
      @media (max-width: 323.98px) {
        max-width: 100% !important;
      }
    }
  }
  .filter-apply-wrapper {
    justify-content: flex-start;
  }
}
.title-with-btn {
  button {
    min-height: unset;
    height: auto;
  }
}
.bid-module-wrapper {
  @media (max-width: 550.98px) {
    flex-direction: column-reverse;
  }
  .content-header-right {
    @media (max-width: 550.98px) {
      height: 42.5px;
    }
    .lead-btn {
      @media (max-width: 550.98px) {
        width: 100%;
        max-width: calc(100% - 59px);
      }
    }
  }
}
.bids-drawer {
  .MuiDrawer-paper {
    min-width: unset !important;
    max-width: 450px;
    width: 100%;
  }
  .filter-button-wrapper {
    @media (max-width: 480.98px) {
      button {
        max-width: calc(50% - 8px);
        width: 100% !important;
        &.full-btn {
          max-width: 100%;
        }
      }
    }
  }
}
.rs-picker-daterange-menu {
  .rs-picker-daterange-panel {
    .rs-stack-item {
      @media (max-width: 480.98px) {
        flex-direction: column !important;
        // flex-direction: row !important;
        // width: 246px !important;
        // border: 2px solid red;
        .rs-calendar-body {
          // border: 2px solid pink;
          padding: 4px 4px 2px !important;
        }
        .rs-btn-primary {
          width: 100% !important;
        }
        .rs-btn-link {
          width: 100% !important;
        }
        .rs-picker-toolbar {
          max-width: 100% !important;
          display: block;
        }
        .rs-picker-toolbar-ranges {
          // border: 2px solid black;
          width: 100% !important;
        }
      }
    }
  }
}
.filter-apply-wrapper {
  button {
    @media (max-width: 412.98px) {
      padding: 4px 6px !important;
      min-width: unset !important;
      font-size: 12px !important;
      border-radius: 6px !important;
    }
  }
}
.filter-content-wrapper {
  .action-btn-wrap {
    button {
      margin: 0;
    }
  }
}
.ql-editor {
  // padding: 4px 0 0 0 !important;
  ol,
  ul {
    padding-left: 0 !important;
    margin: 0 !important;
  }
}
.back-btn {
  min-width: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.title-space {
  padding-top: 24px;
  padding-left: 24px;
}
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  flex-wrap: wrap;
  gap: 16px;
  .rows-per-page-wrapper {
    gap: 12px;
    display: flex;
    align-items: center;
    p {
      margin-top: 4px;
      white-space: nowrap;
    }
    .MuiFormControl-root {
      margin-top: 0;
      margin-bottom: 0;
      border-color: #777 !important;
      box-shadow: none !important;
      min-width: 0;
      .MuiInputBase-input {
        padding-left: 10px;
        padding-right: 20px;
        padding-bottom: 1.3rem !important;
      }
      svg {
        right: 0;
        // top: 12px !important;

        padding-top: 0 !important;
      }
      fieldset {
        border-color: #777 !important;
      }
      &.Mui-Focused {
        fieldset {
          border-color: transparent !important;
        }
      }
    }
  }
  .MuiPaginationItem-root {
    border-radius: 8px !important;
  }
}
@media (max-width: 991.98px) {
  .empty-grid-field {
    display: none;
  }
  .main-content {
    margin-left: 0;
  }

  .navbar div {
    .navbar-right {
      width: 100%;
      // border: 3px solid green;
    }
    .navbar-left {
      // border: 3px solid yellow;
      position: fixed;
    }
  }
  .sidebar-wrapper {
    .logo-box {
      // border: 3px solid yellow !important;
      // position: fixed !important;
      .toggle-btn {
        left: 80px;
      }
    }
    z-index: 9;
  }
  .footer-wrapper .secondary-btn {
    margin-right: 16px;
  }
  .back-btn {
    display: none !important;
  }
}
@media (max-width: 768.98px) {
  .page-title {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .user-info {
    .MuiAvatar-root {
      width: 32px !important;
      height: 32px !important;
      position: static;
    }
  }
}
@media (max-width: 414.98px){
  .nav-icon{
    width: 87%;
  }
}
@media (max-width: 360.98px) {
  .page-title {
    max-width: 160px;
  }
}
@media (max-width: 350.98px) {
  .page-title {
    max-width: 130px;
  }
}

.Menu-list-wrapper-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 64px);
  overflow: auto;
}

// -------------------------------
.account-dropdown-menu {
  .MuiMenu-paper {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 16px;
    border-radius: 10px;
    .user-details {
      .user-img {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 60px;
        // height: 40px;
        // border: 2px solid var(--green);
        // width: 100% !important;
        // height: 80px;
        // background-color: var(--grey);
        // padding: 11px;
        // border-radius: 100px;
        // border: 2px solid red;
        // margin: 0 auto;

        @media (max-width: 1560.98px) {
          width: 100%;
          height: 100%;
        }
        img {
          object-fit: contain;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgb(204, 204, 204);
          // padding: 8px;
        }
        // .avtaarImg {
        //   width: 100%;
        //   height: 100%;
        // }
      }
      .user-name {
        //   border: 2px solid var(--green);
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px;
        text-align: center;
      }
      .user-position {
        text-align: center;
        display: block;
        font-weight: 400 !important;
        font-size: 13px;
        line-height: 18px;
      }
    }
    .divider-border {
      background: var(--light-green);
      margin: 15px 0px;
    }
    .organization-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
      gap: 10px;
      .org-img {
        width: 30px;
        height: 30px;
        background-color: var(--black);
        padding: 2px;
        border-radius: 100px;
        border: 2px solid var(--green);
        margin-bottom: 5px;
        img {
          width: 100%;
          border-radius: 100%;
        }
      }
      .org-text {
        h5 {
          font-weight: 500 !important;
          font-size: 15px !important;
          //   line-height: 15px;
          white-space: nowrap;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          position: relative;
          top: -6px;
        }
      }
    }
    .links {
      gap: 15px;
      display: flex;
      flex-direction: column;
      .menu-link {
        // gap: 14px;
        padding: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        min-height: auto;
        &:hover,
        &:focus {
          background-color: transparent;
        }
        .link-icon {
          margin-left: 6px;
          min-width: 22px;
          opacity: 0.6 !important;
          font-size: 18px !important;
        }
        .link-text {
          margin-left: 10px;
        }
      }
    }
  }
}
.no-scroll {
  overflow: hidden;
}

.action-btn.copy-btn {
  background-color: var(--convert-btn);

  span {
    svg {
      fill: var(--convert-btn-icon);
      font-size: 20px;
    }
  }
  &:hover {
    background-color: var(--convert-btn-hover);
  }
}
