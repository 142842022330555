.status {
  position: relative;
  font-weight: 500;
  padding-left: 15px;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 2px;
  }
  &.live {
    &:before {
      background-color: var(--green);
    }
  }
  &.beta-testing {
    &:before {
      background-color: var(--view-btn-icon);
    }
  }
  &.development {
    &:before {
      background-color: var(--convert-btn-icon);
    }
  }
}
.add-left-space {
  margin-left: 8px;
}
.content-wrapper {
  margin: 24px 32px 32px 32px;
  @media (max-width: 991.98px) {
    margin: 24px 16px 24px 16px;
  }
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .divider {
    border-color: var(--body-bg);
  }
  .card-wrapper {
    background-color: var(--white);
    border-radius: 12px;
    padding: 24px;

    &.add-scroll-space {
      padding: 24px 14px 24px 24px;
      .over-flow-auto {
        overflow: auto;
        padding-right: 10px;
      }
    }
    &.add-bottom-scroll-space {
      padding: 24px 24px 14px 24px;
      .over-flow-bottom-auto {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;
      }
    }
  }

  .view-info {
    .label {
      font-size: 14px;
    }
    span {
      font-size: 16px;
    }
    .tag-wrap {
      flex-wrap: wrap;
      .MuiChip-root {
        border-radius: 6px;
        background: rgba(217, 217, 217, 0.5);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
        padding: 8px 16px;
      }
    }
    .project-link-wrapper {
      padding-left: 0;
      li {
        display: flex;
        align-items: baseline;
        padding-left: 0;
        label {
          white-space: nowrap;
          margin-right: 8px;
        }
        a {
          word-break: break-all;
        }
      }
    }
    .reference-link-wrapper {
      word-break: break-all;
    }
  }
  .document-card-content-wrapper {
    max-height: 420px;
    .document-card-wrapper {
      border-radius: 6px;
      border: 1px solid var(--border);
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      span {
        color: var(--primary-text-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        //word-break: break-all;
      }
    }
  }
  .cast-wrapper {
    max-height: 420px;
    .project-cast-card {
      position: relative;
      .cast-object {
        border-radius: 6px;
        height: 124px;
        object-fit: cover;
        width: 100%;
      }
      .cast-overlay {
        border-radius: 6px;
        background: radial-gradient(
          43.35% 43.35% at 49.85% 46.85%,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
      }
      .cast-play-btn {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        cursor: pointer;
        height: 36px;
        width: 36px;
        svg {
          font-size: 36px;
          path {
            color: var(--white) !important;
          }
        }
      }
      .cast-close-btn {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        height: 21px;
        width: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: var(--white);
        svg {
          font-size: 16px;
          path {
            color: var(--primary-text-color) !important;
          }
        }
      }
      .cast-details {
        background-color: var(--primary-text-color);
        padding: 8px 10px;
        border-radius: 0 0 6px 6px;
        position: absolute;
        bottom: 0;
        // width: 100%;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          color: var(--white);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        svg {
          font-size: 15px;
          cursor: pointer;
          path {
            color: var(--white);
          }
        }
      }
    }
    &.cast-value-wrapper {
      display: flex;
      gap: 16px;
      .project-cast-card {
        height: 171px;
        min-width: 171px;
        @media (max-width: 1560.98px) {
          height: 124px;
          min-width: 124px;
        }
        .cast-object {
          width: 171px;
          height: 171px;
          @media (max-width: 1560.98px) {
            height: 124px;
            min-width: 124px;
          }
        }
        .cast-play-btn {
          top: 50%;
        }
      }
    }
  }
  .ck-editor-wrapper {
    .ck-toolbar {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }
    .ck-editor__editable {
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      min-height: 150px;
    }
    .ck.ck-button.ck-on,
    a.ck.ck-button.ck-on {
      color: var(--white);
    }
    .ck.ck-button.ck-on,
    a.ck.ck-button.ck-on {
      background: var(--primary-text-color);
      border-radius: 5px;
    }
    .ck.ck-button:not(.ck-disabled):hover,
    a.ck.ck-button:not(.ck-disabled):hover {
      background: var(--primary-text-color);
      border-radius: 5px;
      color: var(--white);
    }
  }
  .add-multi-input {
    display: flex;
    gap: 16px;
    .action-btn-wrap {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .drop-zone {
    .drop-zone-inner {
      background-color: var(--secondary-text-color);
      box-shadow: none;
      border-radius: 5px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px;
      .msg-wrap {
        border: 2px dotted var(--textgrey);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: var(--white);
        width: 280px;
        height: 280px;
        border-radius: 50%;
        cursor: pointer;
        h6 {
          color: var(--primary-text-color);
          font-size: 15px;
          margin: 16px 0 8px 0;
          font-weight: 600;
        }
        .sub-msg {
          font-size: 13px;
          margin: 0 0;
          color: var(--primary-text-color) !important;

          span {
            font-weight: 500;
          }
        }
        svg {
          font-size: 28px;
        }
      }
    }
    &.video-cast-dropzone {
      .drop-zone-inner {
        .msg-wrap {
          height: 250px;
          width: 250px;
        }
      }
    }
    &.screenshot-cast-dropzone {
      .drop-zone-inner {
        .msg-wrap {
          height: 250px;
          width: 250px;
          margin-top: 43.5px;
          margin-bottom: 44px;
          @media (max-width: 768.98px) {
            margin: 0;
          }
        }
      }
    }
  }
  .document-list-wrapper {
    .document-upload-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .document-type {
        display: flex;
        align-items: center;
        gap: 16px;
        img {
          width: 25px;
          height: 25px;
        }
        span {
          color: var(--primary-text-color);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .document-action {
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          color: var(--textgrey);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .action-btn {
          min-width: 30px !important;
          height: 30px;
          border-radius: 5px;
          svg {
            font-size: 16px;
          }
        }
      }
    }
  }
  .vd-ss-wrapper {
    .vs-ss-upload-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .document-type {
        display: flex;
        align-items: center;
        gap: 16px;
        img {
          width: 25px;
          height: 25px;
        }
        span {
          color: var(--primary-text-color);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .document-action {
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          color: var(--textgrey);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .action-btn {
          min-width: 30px !important;
          height: 30px;
          border-radius: 5px;
          svg {
            font-size: 16px;
          }
        }
      }
    }
    .vs-ss-upload-wrapper > :not(:first-child) {
      margin-top: 16px;
    }
  }
}
.muilt-select-field {
  .select__control {
    border-radius: 10px;
    min-height: 40px;

    &:hover,
    &:focus,
    &.select__control--is-focused {
      border-color: var(--primary-text-color) !important;
      box-shadow: none;
    }
    .select__multi-value {
      border-radius: 6px;
      // height: 24px;
      padding: 6px;
      color: var(--textgrey);
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background-color: rgba(217, 217, 217, 0.5);
    }
    .select__menu {
      max-height: none; /* Remove any max-height restriction */
    }
  }
}
.side-drawer {
  .MuiDrawer-paper {
    .sidebar-tab-wrapper {
      &.activity-log-wrapper {
        .sidebar-tab {
          .tab-label-btn {
            padding-left: 0;
            padding-right: 0;
          }
          .MuiTabs-indicator {
            height: 3px;
            background-color: var(--primary-text-color);
            border-radius: 5px;
          }
        }
        .add-activity-log-btn {
          justify-content: end;
          display: flex;
          width: 100%;
          button {
            margin-right: 10px;
          }
        }
        .filter-content-wrapper {
          max-width: 1000px;
        }
        .activity-log-list-wrapper {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 160px);
          padding-right: 10px !important;
          overflow: auto;
          .activity-card-wrapper {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            position: relative;
            padding-bottom: 36px !important;
            &:not(:last-child) {
              &:before {
                content: '';
                width: 2px;
                height: 100%;
                position: absolute;
                left: 25px;
                background: rgba(217, 217, 217, 0.5);
                z-index: -1;
              }
            }

            .activity-log-icon {
              min-width: 50px;
              height: 50px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                width: 24px;
                height: 24px;
              }
              &.activity-create {
                background-color: #ebf1fb;
                svg {
                  path {
                    color: #2e77f2;
                  }
                }
              }
              &.activity-status {
                background-color: #f8e3e3;
                svg {
                  path {
                    color: #fa5757;
                  }
                }
              }
              &.activity-assign {
                background-color: #f3e4fc;
                svg {
                  path {
                    color: #62009d;
                  }
                }
              }
              &.activity-estimation {
                background-color: #ffecda;
                svg {
                  path {
                    color: #f0841e;
                  }
                }
              }
              &.activity-edit {
                background-color: #f1feff;
                svg {
                  path {
                    color: #41dbe7;
                  }
                }
              }
              &.activity-call {
                background-color: #e9ffbb;
                svg {
                  path {
                    color: #7cb900;
                  }
                }
              }
            }
            .activity-content-wrapper {
              display: flex;
              align-items: flex-start;
              gap: 24px;
              padding-top: 16px !important;
              width: 100%;
              span {
                color: var(--textgrey);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                white-space: nowrap;
                line-height: normal;
              }
              .activity-content {
                // max-width: calc(100% - 100px);
                width: 100%;
                .activity-content-header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  h6 {
                    color: var(--primary-text-color);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                  span {
                    color: var(--textgrey);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: nowrap;
                  }
                }
                .activity-content-para {
                  border-radius: 10px;
                  background: rgba(124, 185, 0, 0.05);
                  padding: 16px !important;
                  margin-top: 18px;
                  p {
                    color: var(--primary-text-color) !important;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.filter-apply-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 0px 0px 0 0px;
  .outline-btn {
    &.add-btn {
      span {
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        font-size: 13px;
        svg {
          fill: var(--primary-text-color);
        }
      }
    }
  }
}
.content-header {
  &.project-list-header {
    .content-header-right {
      flex-wrap: wrap;
      @media (max-width: 1024.98px) {
        justify-content: flex-end;
      }
    }
  }
}

.custom-style {
  color: #232323 !important;
}

.rs-picker-date-menu {
  z-index: 9999 !important;
  .rs-btn.rs-btn-primary {
    background-color: var(--primary-text-color);
    color: #fff;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    background-color: var(--green);
    color: #fff !important;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: var(--primary-text-color);
  }
  .rs-calendar-time-view .rs-calendar-header-title-time {
    color: var(--primary-text-color);
  }
  .rs-calendar-time-dropdown-cell:hover,
  .rs-calendar-table-cell-content:hover {
    background-color: var(--secondary-text-color);
    color: var(--priamry-text-color);
  }
}
.modal-date-picker {
  width: 100%;
  border-color: var(--border);
  border-radius: 10px;
  .rs-picker-toggle.rs-btn {
    border-radius: 10px;
    // padding-top: 9.2px !important;
    // padding-bottom: 9.2px !important;
    .rs-picker-toggle-placeholder,
    .rs-picker-toggle-textbox {
      color: rgba(35, 35, 35, 0.3);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .rs-picker-toggle-value {
      color: var(--primary-text-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &:not(.rs-picker-disabled) .rs-picker-toggle-active,
  &:hover .rs-picker-toggle {
    border-color: var(--primary-text-color) !important;
    box-shadow: unset !important;
  }
}
.no-msg {
  font-size: 16px;
  font-weight: 500;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.ql-toolbar.ql-snow {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 150px;
}

.ql-snow.ql-toolbar button:hover {
  background: var(--primary-text-color);
  border-radius: 5px;
  color: var(--white);
}

.ql-snow.ql-toolbar button.ql-active {
  background: var(--primary-text-color);
  border-radius: 5px;
  color: var(--white) !important;
}

.ql-snow.ql-toolbar button svg {
  color: var(--white);
}
.ql-editor {
  font-size: initial !important;
}

.ql-indent-1 span {
  font-size: initial !important;
}

.companyicons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--edit-btn);
}
svg {
  font-size: 20px;
}

.not-visible-drag-icon {
  height: 20px;
  width: 20px;
}

.project-module-drawer {
  .filter-button-wrapper {
    button {
      width: 100% !important;
      max-width: calc(32.5% - 8px) !important;
      &.clear-filter-tab-1 {
        max-width: 100% !important;
      }
      @media (max-width: 440.98px) {
        max-width: calc(50% - 8px) !important;
      }
      &.cancel-filter-btn {
        @media (max-width: 440.98px) {
          max-width: 100% !important;
        }
      }
    }
  }
  .sidebar-tab-wrapper {
    .sidebar-tab {
      .MuiTabs-scroller {
        @media (max-width: 430.98px) {
          max-width: 280px;
          overflow: auto !important;
        }
        @media (max-width: 360.98px) {
          max-width: 200px;
        }
      }
    }
  }
}

.project-module-wrapper {
  flex-wrap: wrap-reverse;
  @media (max-width: 768.98px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .filter-apply-wrapper {
    @media (max-width: 768.98px) {
      justify-content: flex-start;
    }
  }
  .content-header-right {
    @media (max-width: 768.98px) {
      width: 100%;
    }
    .search-bar {
      @media (max-width: 768.98px) {
        width: 100%;
        max-width: calc(100% - 332px);
        .MuiInputBase-input {
          width: 100%;
        }
      }
      @media (max-width: 650.98px) {
        max-width: 100%;
      }
    }
    button {
      @media (max-width: 650.98px) {
        width: 100%;
        max-width: calc(50% - 8px);
      }
      @media (max-width: 380.98px) {
        max-width: 100%;
      }
    }
  }
}
.document-card-content-wrapper {
  .add-multi-input {
    @media (max-width: 500.98px) {
      flex-direction: column;
    }
  }
}
.view-project-module-wrapper {
  flex-wrap: wrap-reverse;
  .content-header-right {
    @media (max-width: 820.98px) {
      width: 100%;
    }
    button {
      &.add-btn {
        @media (max-width: 720.98px) {
          width: 100% !important;
          max-width: calc(50% - 8px) !important;
        }
        @media (max-width: 440.98px) {
          max-width: 100% !important;
        }
        &:nth-child(3) {
          @media (max-width: 720.98px) {
            max-width: calc(100% - 60px) !important;
          }
        }
      }
    }
  }
}

.project-view-drawer {
  .MuiDrawer-paper {
    max-width: 900px !important;
  }
}
